.mentions-container {
  min-height: 40vh;
  padding-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  background-color: #020122;
  padding-bottom: 100px;
  z-index: 1;
}

.mention-icons {
  color: #ff521b;
  font-size: 50px;
  position: absolute;
  opacity: 0.5;
  z-index: -1;
}

.mention-icons.people {
  right: 30%;
  top: -5%;
  transform: rotate(7deg);
}

.mention-icons.gift {
  top: 90%;
  right: 60%;
  transform: rotate(-7deg);
}

.mention-boxes {
  width: 85%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mention-box {
  width: 32%;
  height: 300px;
  border: 3px solid;
  border-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2%;
  transition: border-color 0.1s, transform 0.2s;
}

.mention-box:hover {
  border-color: rgba(255, 82, 27, 1);
  transform: scale(102%);
}

.mention-box h2 {
  font-weight: bold;
}

.mention-box h5 {
  margin: 10px auto 20px auto;
  color: #818091;
  font-weight: bold;
}

.mention-box a {
  position: absolute;
  right: 5%;
  top: 5%;
  font-size: 35px;
  color: #ff521b;
  opacity: 0.5;
  transition: transform 0.2s ease-in-out;
}

.mention-box a svg {
  color: #ff521b;
}

.mention-skills {
  display: flex;
  flex-wrap: wrap;
}

.mention-skills span {
  color: white;
  padding-right: 10px;
  font-size: 20px;
  transition: transform 0.2s ease-in-out;
  opacity: 50%;
}

.mention-skills span:hover,
.mention-box a:hover {
  transform: scale(102%);
  opacity: 100%;
}

.mentions-container h4 {
  color: #ff521b;
  width: 85%;
  position: absolute;
  bottom: 0;
}

@media screen and (max-width: 1280px) {
  .mention-box {
    width: 48%;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 765px) {
  .mention-icons.people {
    right: 75%;
    top: 0%;
    transform: rotate(7deg);
  }

  .mention-icons.gift {
    top: 95%;
    right: 20%;
    transform: rotate(-7deg);
  }

  .mention-boxes {
    justify-content: center;
    width: 95%;
  }
  .mention-box {
    width: 90%;
    height: 225px;
  }

  .mention-box h5 {
    width: 100%;
  }

  .mentions-container h4 {
    text-align: center;
  }
}

@media screen and (max-width: 1000px) {
  .mentions-container {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 580px) {
  .mentions-container h4 {
    width: 90%;
  }

  .mention-icons.gift {
    display: none;
  }
}
