.App {
  text-align: center;
  --silver: #c6c5b9ff;
  --steel-teal: #62929eff;
  --cadet: #4a6d7cff;
  --army-green: #393a10ff;
  --deep-space-sparkle: #475657ff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
