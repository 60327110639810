.about-container {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #020122;
  color: white;
  flex-direction: column;
  position: relative;
}

.about-container svg {
  color: #ff521b;
  z-index: 1;
  position: absolute;
}

.about-box {
  min-height: 375px;
  display: flex;
  width: 85%;
  justify-content: space-between;
  z-index: 1;
  /* background-color: red; */
}

.about-profile {
  width: 300px;
  height: 300px;
  border: 3px solid #ff521b;
  border-radius: 20px;
  margin-bottom: 50px;
  overflow: hidden;
  position: relative;
  transition: transform 0.2s;
  z-index: 1;
}

.about-profile:hover {
  transform: scale(102%);
}

.image-filter {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  background-color: rgba(2, 1, 34, 0.5);
  transition: background 0.1s;
}

.image-filter:hover {
  background-color: transparent;
}

.about-profile img {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 10px;
  object-fit: cover;
  object-position: left -20px;
  /* transform: scale(120%); */
}

.about-text {
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 60%;
}

.about-text p {
  font-size: 16px;
}

.about-skills {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
}

.about-header {
  font-size: 40px;
  margin-top: -10px;
}

.about-container .about-small-text span {
  font-size: 25px;
}

.about-skills span {
  background-color: #ff521b;
  color: white;
  padding: 5px 10px;
  margin: 0px 3px 10px 3px;
  border-radius: 5px;
  font-size: 20px;
  transition: transform 0.2s ease-in-out;
}

.about-skills span:hover {
  transform: scale(105%);
}

.about-text .header-orange-red {
  color: #ff521b;
}

.about-container h4 {
  color: #ff521b;
  position: absolute;
  bottom: 0;
}

/* positioning for each floating icon */
.about-container .icon-cube {
  right: 10%;
  top: 0;
  font-size: 60px;
  opacity: 0.5;
}

.about-container .icon-gears {
  right: 40%;
  top: 80%;
  font-size: 70px;
  opacity: 0.5;
}
/* start media queries */
@media screen and (max-width: 975px) {
  .about-profile {
    height: 250px;
    width: 250px;
  }
}

@media screen and (max-width: 850px) {
  .about-text {
    width: 55%;
  }
  .about-box h1 {
    font-size: 30px;
  }

  .about-skills span {
    padding: 5px;
    font-size: 16px;
  }
}

@media screen and (max-width: 765px) {
  .about-container {
    padding-bottom: 75px;
  }
  .about-box {
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-profile {
    height: 300px;
    width: 300px;
  }

  .about-text {
    width: 90%;
  }

  .about-box h1 {
    text-align: center;
    margin: -30px auto 20px auto;
  }

  .about-box h5 {
    margin-left: 3%;
  }

  .about-skills {
    justify-content: center;
    margin-left: 2%;
  }

  .about-container h4 {
    width: 90%;
    text-align: center;
  }

  .about-container .icon-gears {
    right: 80%;
    top: 30%;
  }
}

@media screen and (max-width: 580px) {
  .about-box h5 {
    font-size: 18px;
  }

  .about-container .icon-gears {
    right: 75%;
    font-size: 60px;
  }
}

@media screen and (max-width: 430px) {
  .about-text {
    width: 98%;
  }

  .about-box h5 {
    font-size: 16px;
  }

  .about-box h1 {
    font-size: 24px;
  }

  .about-profile {
    height: 275px;
    width: 275px;
  }

  .about-skills span {
    padding: 4px;
  }
}
