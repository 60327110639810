.hero-container {
  height: 80vh;
  padding-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  position: relative;
  background-color: #020122;
}

/* this area is for the nav */
.hero-container .hero-links {
  width: 60%;
  position: absolute;
  top: 40px;
  right: 20px;
  justify-content: space-around;
  height: 35px;
}

#nav-bars {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  padding-left: 90%;
  padding-top: 20px;
}

#nav-bars .hamburger-links {
  display: none;
}

.nav-bar {
  display: block;
  width: 30px;
  margin: 6px 0;
  height: 4px;
  background-color: #fff;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

#nav-bars.active {
  min-height: 100px;
  z-index: 10;
  background-color: #020122;
}

#nav-bars.active .hamburger-links {
  display: flex;
  position: absolute;
  width: 100%;
  background-color: #020122;
  flex-direction: column;
  left: 0;
  justify-content: center;
  align-items: center;
}

.hamburger-links h5 {
  margin: 20px auto;
}

.hamburger-links h5:hover {
  color: #ff521b;
}

.hamburger-links h5:nth-child(3) {
  margin-bottom: 50px;
}

.hamburger-links #nav-bars.active .nav-bar {
  background-color: #ff521b;
}

#nav-bars.active .nav-bar:nth-child(2) {
  opacity: 0;
}

#nav-bars.active .nav-bar:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

#nav-bars.active .nav-bar:nth-child(3) {
  transform: translateY(-11px) rotate(-45deg);
}

.hero-container h5 a {
  text-decoration: none;
  color: white;
}

.hero-container h5 a:hover {
  color: #ff521b;
}

.hero-container .hero-icons {
  color: #ff521b;
  position: absolute;
  opacity: 0.5;
}

.hero-container .closing-tag {
  font-size: 80px;
  right: 10%;
  top: 50%;
  transform: rotate(36deg);
}

.hero-container .code-branch {
  font-size: 60px;
  top: 20%;
  right: 55%;
  transform: rotate(-20deg);
}

.hero-container .code-commit {
  font-size: 50px;
  top: 16%;
  right: 28%;
  transform: rotate(20deg);
}

.hero-container .code-file {
  font-size: 50px;
  top: 75%;
  right: 40%;
  transform: rotate(-10deg);
}

.hero-container .icon-weight {
  font-size: 60px;
  left: 30%;
  top: 80%;
  transform: rotate(20deg);
}

.hero-container h4 {
  color: #ff521b;
  position: absolute;
  bottom: 2%;
}

.hero-container h5:hover {
  color: #ff521b;
}

/* this area is for all the typewriter animations */
:root {
  --typewriterSpeed: 1s;
  --typewriterCharacters: 22;
}

h3 {
  font-size: clamp(1rem, 3vw + 1rem, 4rem);
  position: relative;
  position: relative;
  width: max-content;
}

h3::before,
h3::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

h3::before {
  background: #020122;
  animation: typewriter var(--typewriterSpeed)
    steps(var(--typewriterCharacters)) 1s forwards;
}

h3::after {
  width: 0.125em;
  animation: typewriter var(--typewriterSpeed)
      steps(var(--typewriterCharacters)) 1s forwards,
    blink 750ms steps(var(--typewriterCharacters)) infinite;
}

.subtitle {
  width: 100%;
  opacity: 0;
  font-size: 60px;
  transform: translateY(3rem);
  animation: fadeInUp 1s ease calc(var(--typewriterSpeed) + 1.5s) forwards;
}

.subtitles {
  width: 85%;
}

.subtitles .middle {
  margin: 30px auto;
}

.subtitles .smaller {
  font-size: 20px;
}

.subtitle span {
  color: #ff521b;
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

@keyframes blink {
  to {
    background: transparent;
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* these are for responsiveness */
@media screen and (max-width: 765px) {
  .subtitles {
    width: 90%;
  }

  .hero-container h1 {
    font-size: 45px;
  }

  .subtitles h4 {
    width: 90%;
    text-align: center;
  }

  .hero-container .closing-tag {
    right: 10%;
    top: 50%;
  }

  .hero-container .code-branch {
    top: 10%;
    right: 35%;
  }

  .hero-container .code-commit {
    top: 19%;
    right: 15%;
  }

  .hero-container .code-file {
    top: 80%;
    right: 30%;
  }

  .hero-container .icon-weight {
    left: 20%;
    top: 75%;
  }
}

@media screen and (max-width: 580px) {
  .hero-container h1 {
    font-size: 34px;
  }

  .hero-container h3 {
    font-size: 24px;
  }

  .subtitles .smaller {
    font-size: 20px;
  }

  .hero-container .code-branch {
    top: 15%;
    right: 70%;
  }

  .hero-container .code-file {
    top: 70%;
    right: 30%;
  }

  .hero-container .icon-weight {
    left: 10%;
    top: 65%;
  }

  #nav-bars {
    padding-left: 85%;
  }
}

@media screen and (max-width: 430px) {
  .subtitles .smaller {
    font-size: 16px;
  }
}
