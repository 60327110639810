.projects-container {
  min-height: 80vh;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  position: relative;
  background-color: #020122;
  padding-bottom: 50px;
}

.project {
  width: 85%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 75px;
  position: relative;
  z-index: 1;
}

.project.left {
  flex-direction: row-reverse;
}

.project-icons {
  color: #ff521b;
  font-size: 50px;
  position: absolute;
  opacity: 0.5;
  z-index: -1;
}

.project-icons.utensils,
.project-icons.ghost {
  top: -10%;
  right: 65%;
  transform: rotate(7deg);
}

.project-icons.calculator,
.project-icons.hotdog {
  top: 90%;
  right: 75%;
  transform: rotate(-7deg);
}

.project-icons.fish {
  top: 10%;
  right: 5%;
  transform: rotate(7deg);
}

.project-text {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-text h2 {
  font-size: 24px;
  font-style: italic;
}

.project-text h1 {
  font-weight: bold;
  font-size: 40px;
}

.project-text h5 {
  margin: 10px auto 20px auto;
}

.project-skills {
  display: flex;
  flex-wrap: wrap;
}

.project-skills span {
  color: white;
  padding-right: 10px;
  font-size: 20px;
  transition: transform 0.2s ease-in-out;
  opacity: 50%;
}

.project-links {
  margin-top: 20px;
  display: flex;
}

.project-links svg {
  color: #ff521b;
  font-size: 30px;
  opacity: 50%;
  margin-right: 15px;
  transition: transform 0.2s ease-in-out;
}

.project-skills span:hover,
.project-links svg:hover {
  transform: scale(102%);
  opacity: 100%;
}

.project-img {
  width: 55%;
  /* width: 450px; */
  height: 350px;
  border: 3px solid #ff521b;
  border-radius: 20px;
  overflow: hidden;
  transition: transform 0.2s;
  position: relative;
}

.project-img:hover {
  transform: scale(102%);
}

.project-img-filter {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  background-color: rgba(2, 1, 34, 0.5);
  transition: background 0.1s;
}

.project-img-filter:hover {
  background-color: transparent;
}

.project-img img {
  height: 100%;
  object-fit: cover;
  object-position: left 0;
}

.projects-container h4 {
  color: #ff521b;
  width: 85%;
  position: absolute;
  bottom: 0;
}

@media screen and (max-width: 975px) {
  .project-img {
    width: 50%;
    height: 300px;
  }
}

/* supposed to be a query to reduce header size (like in about section) */
@media screen and (max-width: 850px) {
  .project-text h2 {
    font-size: 20px;
  }
  .project-text h1 {
    font-size: 30px;
  }
}

/* this is when it should flip to stacking */
@media screen and (max-width: 765px) {
  .project {
    width: 95%;
    flex-direction: column-reverse;
    align-items: center;
    margin: -50px 0 100px 0;
  }

  .project.left {
    flex-direction: column-reverse;
  }

  .project-icons.calculator,
  .project-icons.hotdog {
    top: 95%;
    right: 20%;
    transform: rotate(-7deg);
  }

  .project-icons.utensils,
  .project-icons.ghost {
    top: 55%;
    right: 40%;
    transform: rotate(7deg);
  }

  .project-icons.fish {
    top: 55%;
    right: 25%;
    transform: rotate(7deg);
  }

  .project-text {
    width: 95%;
    margin-top: 20px;
  }

  .project-text h1 {
    margin-top: -5px;
  }

  .project-img {
    width: 95%;
    height: 375px;
  }

  .projects-container h4 {
    text-align: center;
  }
}

@media screen and (max-width: 580px) {
  .project-text h5,
  .project-skills span {
    font-size: 18px;
  }

  .project-text,
  .project-img,
  .projects-container h4 {
    width: 90%;
  }
}
@media screen and (max-width: 430px) {
  .project-img {
    min-width: 275px;
    height: 183px;
  }

  .project-img img {
    height: 190px;
  }

  .project-text h1 {
    font-size: 24px;
  }
  .project-text h5,
  .project-skills span {
    font-size: 16px;
  }
}
