.contact-container {
  min-height: 80vh;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  position: relative;
  background-color: #020122;
  padding-bottom: 50px;
  z-index: 1;
}

.contact-icons {
  color: #ff521b;
  font-size: 50px;
  position: absolute;
  opacity: 0.5;
  z-index: -1;
}

.contact-icons.keyboard {
  right: 10%;
  top: 10%;
  transform: rotate(7deg);
}

.contact-icons.smile {
  right: 80%;
  top: 80%;
  transform: rotate(7deg);
}

.contact-container h1 {
  margin: 20px auto;
}

.contact-form {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
  position: relative;
  z-index: 1;
}

.contact-form label {
  color: #818091;
  font-weight: bold;
  font-size: 20px;
}

.contact-form input,
.contact-form textarea {
  background-color: #020122;
  color: white;
  border: 2px solid;
  border-color: rgba(255, 255, 255, 0.4);
  font-size: 20px;
  margin: 5px 0 20px 0;
  border-radius: 5px;
  transition: border-color 0.1s, transform 0.2s;
}

.contact-form input:hover,
.contact-form textarea:hover {
  border-color: rgba(255, 82, 27, 1);
  transform: scale(101%);
}

.contact-form input {
  height: 50px;
}

.contact-form textarea {
  padding: 10px;
}

.contact-form textarea:focus {
  border: 2px solid;
  border-color: rgba(255, 255, 255, 0.4);
}

.contact-form input:focus,
textarea:focus {
  background-color: transparent;
  color: white;
}

.custom-submit-btn {
  height: 50px;
  width: 50%;
  margin: 30px auto;
  border: 2px solid !important;
  border-color: rgba(255, 255, 255, 0.4) !important;
  color: #818091 !important;
  background-color: #020122 !important;
  font-weight: bold !important;
  transition: border-color 0.1s, transform 0.2s;
}

.custom-submit-btn:hover {
  border-color: rgba(255, 82, 27, 1) !important;
  transform: scale(101%);
  color: rgba(255, 82, 27, 1) !important;
}

@media screen and (max-width: 850px) {
  .contact-container h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 765px) {
  .contact-icons.keyboard {
    top: 0;
    transform: rotate(7deg);
  }

  .contact-container h1 {
    text-align: center;
  }
  .contact-form {
    width: 85%;
  }
}

@media screen and (max-width: 430px) {
  .contact-container h1 {
    font-size: 24px;
  }
}
